import React, { FC } from "@messenger/lib/teact/teact";

import Button from "@messenger/components/ui/Button";

import appInactivePath from "@messenger/assets/app-inactive.png";
import appInactiveBusinessPath from "@messenger/assets/business/app-inactive.png";

import "@messenger/components/main/AppInactive.scss";
import {
  IS_BUSINESS_APP,
  IS_IHC_WHITELABEL,
  PAGE_TITLE,
} from "@messenger/config";

const AppInactive: FC = () => {
  const handleReload = () => {
    window.location.reload();
  };

  const imgPath = IS_BUSINESS_APP
    ? appInactiveBusinessPath
    : IS_IHC_WHITELABEL
      ? appInactiveBusinessPath
      : appInactivePath;
      
  return (
    <div id="AppInactive">
      <div className="content">
        <img className="m-auto" src={imgPath} alt="" />
        <h3 className="title">Such error, many tabs</h3>
        <div className="description">
          {PAGE_TITLE} supports only one active tab with the app.
          <br />
          Please reload this page to continue using this tab or close it.
        </div>
        <div className="actions">
          <Button isText ripple onClick={handleReload}>
            Reload app
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppInactive;
